import { useMemo, useState } from 'react';

import { useAmplitude } from './useAmplitude';

interface UsePaginationProps<T> {
    items: T[];
    limit?: number;
    defaultPage?: number;
}

export function usePagination<T>({
    limit = 10,
    defaultPage = 1,
    items
}: UsePaginationProps<T>) {
    const { sendEvent } = useAmplitude();
    const pages = items.reduce(
        (result, item, index) => {
            const chunkIndex = Math.floor(index / limit);

            if (!result[chunkIndex]) {
                // eslint-disable-next-line no-param-reassign
                result[chunkIndex] = [];
            }

            result[chunkIndex].push(item);

            return result;
        },
        [] as unknown as [T[]]
    );

    const [page, setPage] = useState(
        pages.length >= defaultPage ? defaultPage : pages.length
    );

    const data = useMemo(() => pages[page - 1] || [], [pages, page]);

    const hasNext = page < pages.length;

    const hasPrevious = page > 1;

    const next = () => {
        sendEvent('Pagination - Next clicked');

        if (hasNext) {
            setPage(page + 1);
        }
    };

    const previous = () => {
        sendEvent('Pagination - Previous clicked');

        if (hasPrevious) {
            setPage(page - 1);
        }
    };

    const first = () => {
        sendEvent('Pagination - First clicked');
        setPage(1);
    };

    const last = () => {
        sendEvent('Pagination - Last clicked');
        setPage(pages.length);
    };

    const from = useMemo(() => {
        if (!data.length) {
            return 0;
        }

        if (!hasPrevious) {
            return 1;
        }

        return page * limit - limit + 1;
    }, [data, hasPrevious, page, limit]);

    const to = hasNext ? page * limit : items.length;

    return {
        data,
        hasNext,
        hasPrevious,
        next,
        previous,
        first,
        last,
        from,
        to
    };
}
