import { Pagination } from '@components/Pagination/Pagination';
import { gridTemplateColumns } from '@components/ReportIdentifiedComponents/constants';
import { ReportIdentifiedComponent } from '@components/ReportIdentifiedComponents/ReportIdentifiedComponent';
import {
    badgeToStateMap,
    SortState
} from '@components/ReportIdentifiedComponents/types';
import {
    Box,
    Button,
    Grid,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdSearch,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { BadgeStatus } from '../../api/generated-types';
import { useAmplitude, usePagination } from '../../hooks';
import { useReportStore } from '../../stores';
import { Component } from '../../types';
import { COMPONENTS_LIMIT } from '../../util/constants';
import { SortIcon } from './SortIcon';

const columns = [
    { name: 'name', label: 'reportPage.columnName' },
    { name: 'type', label: 'reportPage.columnType' },
    {
        name: 'recommendedCategory',
        label: 'reportPage.columnRecommendedCategory'
    },
    { name: 'state', label: 'reportPage.columnState' }
];

export const ReportIdentifiedComponents = () => {
    const { t } = useTranslation();
    const { identifiedComponents: rawIdentifiedComponents } = useReportStore();
    const { sendEvent } = useAmplitude();

    const [search, setSearch] = useState('');
    const [sortState, setSortState] = useState<SortState>({
        column: 'state',
        direction: 'desc'
    });

    const identifiedComponents: Component[] = useMemo(
        () =>
            (rawIdentifiedComponents as Component[]).map((component) => ({
                ...component,
                state: component.recommendationIgnored
                    ? badgeToStateMap[BadgeStatus.Grey]
                    : badgeToStateMap[component.badgeStatus as BadgeStatus]
            })),
        [rawIdentifiedComponents]
    );

    const filteredAndSortedComponents: Component[] = useMemo(() => {
        let components = identifiedComponents.filter((component) =>
            component.name.toLowerCase().includes(search.toLowerCase())
        );

        if (sortState.column && sortState.direction !== 'default') {
            const sortBoolean = (
                aValue: boolean,
                bValue: boolean,
                direction: 'asc' | 'desc'
            ) => {
                if (aValue === bValue) {
                    return 0;
                }

                if (direction === 'asc') {
                    return aValue ? -1 : 1;
                }

                return aValue ? 1 : -1;
            };

            const sortNonBoolean = (
                aValue: any,
                bValue: any,
                direction: 'asc' | 'desc'
            ) => {
                if (aValue < bValue) {
                    return direction === 'asc' ? -1 : 1;
                }

                if (aValue > bValue) {
                    return direction === 'asc' ? 1 : -1;
                }

                return 0;
            };

            components = [...components].sort((a, b) => {
                const aValue = a[sortState.column!];
                const bValue = b[sortState.column!];

                const isBoolean =
                    typeof aValue === 'boolean' && typeof bValue === 'boolean';

                if (sortState.direction !== 'default') {
                    if (isBoolean) {
                        return sortBoolean(aValue, bValue, sortState.direction);
                    }

                    return sortNonBoolean(aValue, bValue, sortState.direction);
                }

                return 0;
            });
        }

        return components;
    }, [identifiedComponents, search, sortState]);

    const {
        data,
        hasNext,
        hasPrevious,
        next,
        previous,
        last,
        first,
        from,
        to
    } = usePagination<Component>({
        items: filteredAndSortedComponents,
        limit: COMPONENTS_LIMIT
    });

    const toggleSort = (column: keyof Component) => {
        sendEvent(`Scan report - Sort identified components by ${column}`, {
            column,
            direction: sortState.direction
        });
        setSortState((prevState) => {
            let newDirection: 'asc' | 'desc' | 'default' = 'asc';

            if (prevState.column === column) {
                if (prevState.direction === 'asc') {
                    newDirection = 'desc';
                } else if (prevState.direction === 'desc') {
                    newDirection = 'default';
                }
            }

            return { column, direction: newDirection };
        });
    };

    const handleOnSearch = (e: ChangeEvent<HTMLInputElement>) => {
        sendEvent('Scan report - Search identified components', {
            search: e.target.value
        });
        setSearch(e.target.value);
    };

    const handleOnViewDeclaredComponents = () => {
        sendEvent('Scan report - View declared components clicked');
    };

    if (!identifiedComponents?.length) {
        return null;
    }

    return (
        <Stack spacing="16px">
            <Box
                display="flex"
                alignItems="center"
                gap="18px"
                justifyContent="space-between"
                flexWrap="wrap"
            >
                <Box display="flex" alignItems="center" gap="18px">
                    <Text fontSize="28px" fontWeight="700">
                        {t('reportPage.identifiedComponents')}
                    </Text>
                    <Box
                        as="span"
                        borderRadius={50}
                        py={1}
                        px={3}
                        bg="#EBEBEB"
                        fontSize="13px"
                        fontWeight="700"
                    >
                        {identifiedComponents?.length}
                    </Box>
                </Box>
                <Box display="flex" justifySelf="end" gap="10px">
                    <InputGroup w="auto">
                        <Input
                            placeholder={t('common.searchForComponents')}
                            onChange={handleOnSearch}
                            value={search}
                        />
                        <InputRightElement>
                            <Icon as={MdSearch} />
                        </InputRightElement>
                    </InputGroup>
                    <Link to="/banner/components">
                        <Button onClick={handleOnViewDeclaredComponents}>
                            {t('reportPage.viewDeclaredComponents')}
                        </Button>
                    </Link>
                </Box>
            </Box>

            <Box>
                <Grid
                    px={6}
                    py={2.5}
                    gridTemplateColumns={gridTemplateColumns}
                    fontSize="13px"
                    color="#666"
                    gap="8px"
                >
                    {columns.map((column) => (
                        <Box
                            key={column.name}
                            _hover={{ cursor: 'pointer' }}
                            onClick={() =>
                                toggleSort(column.name as keyof Component)
                            }
                            display="flex"
                            alignItems="center"
                            gap="4px"
                        >
                            {t(column.label)}

                            <SortIcon
                                column={column.name}
                                sortState={sortState}
                            />
                        </Box>
                    ))}
                </Grid>
                <Stack spacing="8px">
                    {data.map((component) => (
                        <ReportIdentifiedComponent
                            component={component}
                            key={component.name}
                        />
                    ))}
                </Stack>
            </Box>
            <Pagination
                from={from}
                to={to}
                items={filteredAndSortedComponents}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                first={first}
                previous={previous}
                next={next}
                last={last}
            />
        </Stack>
    );
};
