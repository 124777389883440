import { Box, Button, Checkbox, Text } from '@usercentrics/cookiebot-ui-react';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegThumbsDown, FaRegThumbsUp } from 'react-icons/fa';

import { useAmplitude } from '../../hooks';
import { useAuthStore } from '../../stores';
import { FeedbackModalView } from '../../util/constants';

type Props = {
    onClose: () => void;
    onViewChange: (view: FeedbackModalView) => void;
    isAutoDisplayed: boolean;
};

const reviewModalKey = 'show-review-modal';

export const DefaultContent = ({
    onClose,
    onViewChange,
    isAutoDisplayed
}: Props) => {
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();
    const { appInstanceId } = useAuthStore();

    const handleOnThumbsUpClick = () => {
        onViewChange(FeedbackModalView.ThumbsUp);
        sendEvent('Feedback - thumbs up clicked');
    };

    const handleOnThumbsDownClick = () => {
        onViewChange(FeedbackModalView.ThumbsDown);
        sendEvent('Feedback - thumbs down clicked');
    };

    const handleOnDontAskAgainChange = (
        event: ChangeEvent<HTMLInputElement>
    ) => {
        const { checked } = event.target;

        if (checked) {
            localStorage.setItem(`${reviewModalKey}-${appInstanceId}`, 'false');

            return;
        }

        localStorage.removeItem(`${reviewModalKey}-${appInstanceId}`);
    };

    return (
        <>
            <Text fontSize="32px" fontWeight={600}>
                {t('feedbackModal.defaultViewText')}
            </Text>
            <Box display="flex" gap="8px" alignItems="center">
                <Button variant="transparent" onClick={handleOnThumbsUpClick}>
                    <FaRegThumbsUp size="64px" />
                </Button>
                <Button variant="transparent" onClick={handleOnThumbsDownClick}>
                    <FaRegThumbsDown color="#5d5d5d" size="64px" />
                </Button>
            </Box>
            {isAutoDisplayed ? (
                <Box display="grid" gap="8px" w="100%">
                    <Button onClick={onClose} variant="transparent">
                        {t('feedbackModal.remindMe')}
                    </Button>
                    <Checkbox
                        justifySelf="end"
                        onChange={handleOnDontAskAgainChange}
                    >
                        {t('common.dontAskAgain')}
                    </Checkbox>
                </Box>
            ) : null}
        </>
    );
};
