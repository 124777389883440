import { Pagination } from '@components/Pagination/Pagination';
import {
    Box,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    MdSearch,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePagination } from '../../hooks';
import { useReportStore } from '../../stores';
import { COMPONENTS_LIMIT } from '../../util/constants';

export const ReportUnknownComponents = () => {
    const { unknownComponents } = useReportStore();
    const { t } = useTranslation();

    const [search, setSearch] = useState('');

    const filteredComponents: string[] = useMemo(() => {
        if (search) {
            return unknownComponents.filter((component) =>
                component.toLowerCase().includes(search.toLowerCase())
            );
        }

        return unknownComponents as string[];
    }, [unknownComponents, search]);

    const {
        data,
        hasNext,
        hasPrevious,
        next,
        previous,
        last,
        first,
        from,
        to
    } = usePagination({ items: filteredComponents, limit: COMPONENTS_LIMIT });

    if (!unknownComponents?.length) {
        return null;
    }

    return (
        <Stack spacing="16px">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="18px">
                    <Text fontSize="28px" fontWeight="700">
                        {t('reportPage.unknownComponents')}
                    </Text>
                    <Box
                        as="span"
                        borderRadius={50}
                        py={1}
                        px={3}
                        bg="#EBEBEB"
                        fontSize="13px"
                        fontWeight="700"
                    >
                        {unknownComponents?.length}
                    </Box>
                </Box>
                <Box justifySelf="end">
                    <InputGroup w="auto">
                        <Input
                            placeholder={t('common.searchForComponents')}
                            onChange={(e: any) => setSearch(e.target.value)}
                            value={search}
                        />
                        <InputRightElement>
                            <Icon as={MdSearch} />
                        </InputRightElement>
                    </InputGroup>
                </Box>
            </Box>
            <Box
                bg="#ECEFFE"
                display="grid"
                gridTemplateColumns="1fr 1fr"
                gap="40px"
                p="24px"
                borderRadius="8px"
            >
                <Text>{t('reportPage.unknownComponentsDescription')}</Text>
                <Box bg="white" p="16px" borderRadius="8px">
                    <Stack spacing="16px">
                        <Box>
                            <Text fontWeight="700">
                                {t('reportPage.familiarUrlsTitle')}:
                            </Text>{' '}
                            {t('reportPage.familiarUrlsDescription')}
                        </Box>
                        <Box>
                            <Text fontWeight="700">
                                {t('reportPage.unfamiliarUrlsTitle')}:
                            </Text>{' '}
                            {t('reportPage.unfamiliarUrlsDescription')}
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Stack spacing="8px">
                {data.map((component) => (
                    <Box
                        key={component}
                        px="24px"
                        py="16px"
                        boxShadow="0 4px 6px 1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
                        border="1px solid #E2E8F0"
                        borderRadius="8px"
                        overflowWrap="anywhere"
                    >
                        {component}
                    </Box>
                ))}
            </Stack>
            <Pagination
                from={from}
                to={to}
                items={filteredComponents}
                hasNext={hasNext}
                hasPrevious={hasPrevious}
                first={first}
                previous={previous}
                next={next}
                last={last}
            />
        </Stack>
    );
};
