import { Divider } from '@chakra-ui/react';
import { AppInitializationError } from '@components/AppInitializationError';
import { ManualScanConfirmationDialog } from '@components/ManualScanConfirmationDialog';
import { ResetScansCount } from '@components/ResetScansCount';
import { ScanAnySite } from '@components/ScanAnySite';
import { useQuery } from '@tanstack/react-query';
import { Box, Flex } from '@usercentrics/cookiebot-ui-react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getScansDetails } from '../../api/api';
import { useAmplitude } from '../../hooks';
import {
    useAdminStore,
    useAppConfigStore,
    useAuthStore,
    useReportStore
} from '../../stores';
import { StatusEnum } from '../../util/constants';
import { ScanStatusDone } from './ScanStatusDone';
import { ScanStatusError } from './ScanStatusError';
import { ScanStatusPending } from './ScanStatusPending';
import { ScanStatusStartButton } from './ScanStatusStartButton';

export const ScanStatus = () => {
    const { t } = useTranslation();
    const { status, isLowRisk, error } = useReportStore();
    const { appError } = useAppConfigStore();
    const { isAdmin } = useAdminStore();
    const { domain, appInstanceId } = useAuthStore();
    const { sendEvent } = useAmplitude();

    const risk = isLowRisk ? StatusEnum.DoneLowRisk : StatusEnum.DoneAtRisk;

    const calculatedStatus = status !== StatusEnum.Done ? status : `${risk}`;

    const isScanning = status === StatusEnum.Pending;

    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
        useState(false);

    const { data } = useQuery({
        queryKey: ['getScansDetails', appInstanceId],
        queryFn: () => getScansDetails(appInstanceId),
        enabled: !!appInstanceId
    });

    const handleOnDialogClose = useCallback(() => {
        sendEvent('Quoted Scans - close re-scan modal');
        setIsConfirmationDialogOpen(false);
    }, [sendEvent]);

    const handleOnRescanClick = useCallback(() => {
        sendEvent('Quoted Scans - open re-scan modal');
        setIsConfirmationDialogOpen(true);
    }, [sendEvent]);

    if (appError) {
        return <AppInitializationError appError={appError} />;
    }

    return (
        <Flex direction="column" gap={5}>
            {!domain ? (
                <Box>{t('common.noDomain')}</Box>
            ) : (
                <>
                    {isScanning ? <ScanStatusPending /> : null}
                    {status === StatusEnum.Done ? (
                        <ScanStatusDone
                            status={calculatedStatus}
                            handleOnRescanClick={handleOnRescanClick}
                        />
                    ) : null}
                    {error && !isScanning ? (
                        <ScanStatusError
                            status={calculatedStatus}
                            handleOnRescanClick={handleOnRescanClick}
                        />
                    ) : null}
                </>
            )}

            {isAdmin ? (
                <>
                    <Divider />
                    <ScanStatusStartButton status={status} />
                    <ScanAnySite />
                    <ResetScansCount />
                </>
            ) : null}

            <ManualScanConfirmationDialog
                isOpen={isConfirmationDialogOpen}
                onClose={handleOnDialogClose}
                {...data}
            />
        </Flex>
    );
};
