import { Divider } from '@chakra-ui/react';
import {
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Grid,
    Stack,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdOutlineEdit, MdOutlineVisibility } from 'react-icons/md';

import { Component } from '../../types';
import { ComponentRecommendedAction } from '../ComponentRecommendedAction';
import defaultClasses from '../ComponentsCategory/ComponentsCategory.module.css';
import { CustomEmbedContent } from './CustomEmbedContent';
import { CustomEmbedModal } from './CustomEmbedModal';

const badgeColorMap: Record<string, string> = {
    red: '500',
    grey: '200'
};

type Props = {
    data: Component[];
    name: string;
    isComponentsPage: boolean;
    withRisk: boolean;
    isOtherServices: boolean;
    isCustomEmbeds: boolean;
    isWixComponents: boolean;
};

export const ComponentItem = (props: Props) => {
    const {
        data,
        name,
        isComponentsPage,
        withRisk,
        isOtherServices,
        isCustomEmbeds,
        isWixComponents
    } = props;

    const { t } = useTranslation();

    const [selectedCustomEmbed, setSelectedCustomEmbed] =
        useState<Component | null>(null);

    const gridTemplateColumns = isComponentsPage
        ? '2.5fr 1fr 1fr 1fr 30px'
        : '1.5fr 1fr 1fr 1fr 30px';

    const grids = useMemo(() => {
        const columnName = t('reportPage.columnName');

        return {
            scanReport: (
                <Grid
                    templateColumns={gridTemplateColumns}
                    px={4}
                    color="rgba(102, 102, 102, 1)"
                >
                    <Text>{columnName}</Text>
                    <Text />
                    <Text>{t('reportPage.columnRecommendedCategory')}</Text>
                    <Text>{t('reportPage.columnBlocked')}</Text>
                </Grid>
            ),
            componentsPage: (
                <Grid
                    templateColumns={gridTemplateColumns}
                    px={4}
                    color="rgba(102, 102, 102, 1)"
                >
                    <Text>{columnName}</Text>
                    <Text>{t('common.show')}</Text>
                    <Text>{t('common.category')}</Text>
                    <Text />
                </Grid>
            ),
            customEmbeds: (
                <Grid
                    templateColumns={gridTemplateColumns}
                    px={4}
                    color="rgba(102, 102, 102, 1)"
                >
                    <Text>{columnName}</Text>
                    <Text>{t('common.show')}</Text>
                    <Text>{t('common.category')}</Text>
                    <Text>Action</Text>
                </Grid>
            )
        };
    }, [gridTemplateColumns, t]);

    const grid = useMemo(() => {
        if (isComponentsPage && !isCustomEmbeds) {
            return grids.componentsPage;
        }

        if (isCustomEmbeds) {
            return grids.customEmbeds;
        }

        return grids.scanReport;
    }, [
        grids.componentsPage,
        grids.customEmbeds,
        grids.scanReport,
        isComponentsPage,
        isCustomEmbeds
    ]);

    const handleOnEditClick = useCallback(
        (event: MouseEvent<HTMLDivElement>, component: Component) => {
            event.preventDefault();
            setSelectedCustomEmbed(component);
        },
        []
    );

    const getBlockedStatus = useCallback(
        (blockedPriorConsent: boolean, badgeStatus: string) =>
            blockedPriorConsent ? (
                <Text align="left" fontWeight={400}>
                    {t('reportPage.blocked')}
                </Text>
            ) : (
                <Tag
                    background={`${
                        badgeStatus === 'grey' ? 'gray' : badgeStatus
                    }.${badgeColorMap[badgeStatus]}`}
                    fontWeight={600}
                    maxWidth={110}
                >
                    <Text align="left" fontWeight={600}>
                        {t('reportPage.notBlocked')}
                    </Text>
                </Tag>
            ),
        [t]
    );

    const getContent = useCallback(
        (component: Component) => ({
            scanReport: (
                <>
                    <Text align="left">{component.name}</Text>
                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {/* {component.blockerCategory} */}
                    </Text>

                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {component.recommendedCategory}
                    </Text>
                    {getBlockedStatus(
                        component.blockedPriorConsent,
                        component.badgeStatus
                    )}
                </>
            ),
            otherServices: (
                <Text align="left" overflow="hidden">
                    {component.name}
                </Text>
            ),
            componentsPage: (
                <>
                    <Text align="left">{component.name}</Text>
                    <Box
                        className={defaultClasses.capitalize}
                        textAlign="left"
                        fontWeight={400}
                    >
                        <Box display="flex" alignItems="center" gap="8px">
                            <MdOutlineVisibility size="24px" />
                            {t('common.yes')}
                        </Box>
                    </Box>
                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {component.blockerCategory}
                    </Text>
                    <Text />
                </>
            ),
            customEmbeds: (
                <>
                    <Text align="left">{component.name}</Text>
                    <Box
                        className={defaultClasses.capitalize}
                        textAlign="left"
                        fontWeight={400}
                    >
                        <Box display="flex" alignItems="center" gap="8px">
                            <MdOutlineVisibility size="24px" />
                            {t('common.yes')}
                        </Box>
                    </Box>
                    <Text
                        className={defaultClasses.capitalize}
                        align="left"
                        fontWeight={400}
                    >
                        {component.blockerCategory}
                    </Text>
                    <Box textAlign="left" fontWeight={600} color="#1032CF">
                        <Box
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            onClick={(event) =>
                                handleOnEditClick(event, component)
                            }
                        >
                            <MdOutlineEdit size="24px" />{' '}
                            <Box>{t('common.edit')}</Box>
                        </Box>
                    </Box>
                </>
            )
        }),
        [getBlockedStatus, handleOnEditClick, t]
    );

    const renderContent = useCallback(
        (component: Component) => {
            if (isOtherServices) {
                return getContent(component).otherServices;
            }

            if (isComponentsPage && !isCustomEmbeds) {
                return getContent(component).componentsPage;
            }

            if (isCustomEmbeds) {
                return getContent(component).customEmbeds;
            }

            return getContent(component).scanReport;
        },
        [getContent, isComponentsPage, isCustomEmbeds, isOtherServices]
    );

    return (
        <>
            {!isOtherServices ? grid : null}
            <Stack spacing={1} mt={2}>
                {data.map((component) => (
                    <AccordionItem
                        key={`tracker-${name}-component-${component.name}`}
                        borderRadius={6}
                        boxShadow="3px 2px 4px 0px #bbbbbb"
                    >
                        <AccordionButton
                            display="grid"
                            gridTemplateColumns={
                                !isOtherServices ? gridTemplateColumns : '1fr'
                            }
                            color="black"
                            _expanded={{
                                color: 'black',
                                borderBottomWidth: '1px',
                                borderColor: 'gray.100'
                            }}
                            _hover={{
                                color: 'black'
                            }}
                        >
                            {renderContent(component)}
                            {!isOtherServices ? <AccordionIcon /> : null}
                        </AccordionButton>
                        {!isOtherServices ? (
                            <AccordionPanel>
                                <Text>{component.description}</Text>
                                {isCustomEmbeds ? (
                                    <CustomEmbedContent component={component} />
                                ) : null}
                            </AccordionPanel>
                        ) : null}
                        {withRisk && !isOtherServices ? (
                            <>
                                <Divider color="lightgrey" />
                                <ComponentRecommendedAction
                                    isWixComponents={isWixComponents}
                                    recommendedAction={
                                        component.recommendedAction.value
                                    }
                                />
                            </>
                        ) : null}
                    </AccordionItem>
                ))}
            </Stack>
            <CustomEmbedModal
                component={selectedCustomEmbed}
                isOpen={!!selectedCustomEmbed}
                onClose={() => setSelectedCustomEmbed(null)}
            />
        </>
    );
};
