import { LanguageSettingsTab } from '@components/LanguageSettingsTab/LanguageSettingsTab';
import { Loader } from '@components/Loader';
import { TextLabelsTab } from '@components/TextLabelsTab/TextLabelsTab';
import { VisitWebsiteButton } from '@components/VisitWebsiteButton/VisitWebsiteButton';
import {
    Box,
    Button,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../hooks';
import styles from '../Appearance/Appearance.module.css';
import { useContent } from './useContent';

export const Content = () => {
    const {
        textLabelsFormState,
        updateTextLabelsForm,
        updateLanguageSettingsForm,
        languageSettingsFormState,
        editableLanguages,
        languagesListForSelection,
        handleOnSubmit,
        isSaveButtonLoading,
        loading,
        handleOnReset,
        isSaveButtonDisabled,
        supportedLanguages
    } = useContent();

    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const [tabIndex, setTabIndex] = useState(0);

    const handleTabsChange = (index: number) => {
        sendEvent('Content - Tab changed', {
            tab: index
        });
        setTabIndex(index);
    };

    return (
        <>
            <Text fontSize="60px" fontWeight="800">
                {t('contentPage.title')}
            </Text>
            <Tabs index={tabIndex} onChange={handleTabsChange}>
                <TabList
                    className={styles.tabList}
                    py={4}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box display="flex">
                        <Tab>{t('contentPage.textLabelsTab')}</Tab>
                        <Tab>{t('contentPage.languageSettingsTab')}</Tab>
                    </Box>
                    <Box display="flex" gap={4}>
                        <VisitWebsiteButton />
                        <Button
                            width="clamp(60px, 10vw, 132px)"
                            variant="outline"
                            onClick={handleOnReset}
                        >
                            {t('common.reset')}
                        </Button>
                        <Button
                            isLoading={isSaveButtonLoading}
                            onClick={handleOnSubmit}
                            width="clamp(60px, 10vw, 132px)"
                            isDisabled={isSaveButtonDisabled}
                            backgroundColor="#076646"
                        >
                            {t('common.save')}
                        </Button>
                    </Box>
                </TabList>
                {!loading ||
                !textLabelsFormState.selectedLanguage ||
                languageSettingsFormState.addedLanguages.length !== 0 ? (
                    <TabPanels>
                        <TabPanel>
                            <TextLabelsTab
                                formState={textLabelsFormState}
                                updateForm={updateTextLabelsForm}
                                changeTab={handleTabsChange}
                                addedLanguages={languagesListForSelection}
                            />
                        </TabPanel>
                        <TabPanel>
                            <LanguageSettingsTab
                                updateForm={updateLanguageSettingsForm}
                                formState={languageSettingsFormState}
                                editableLanguages={editableLanguages}
                                supportedLanguages={supportedLanguages}
                            />
                        </TabPanel>
                    </TabPanels>
                ) : (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        h="100%"
                        my={10}
                    >
                        <Loader />
                    </Box>
                )}
            </Tabs>
        </>
    );
};
