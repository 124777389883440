import { useToast } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPlaceholdersConfig, setPlaceholdersConfig } from '../api/api';
import { useAuthStore } from '../stores';
import { useAmplitude } from './useAmplitude';

export const usePlaceholdersSwitch = () => {
    const { t } = useTranslation();
    const { appInstanceId } = useAuthStore();
    const toast = useToast();
    const { sendEvent } = useAmplitude();

    const [isChecked, setIsChecked] = useState(false);

    const { data: placeholdersConfigData, isLoading: getPlaceholdersLoading } =
        useQuery({
            queryKey: [
                'getPlaceholdersConfig',
                {
                    appInstanceId
                }
            ],
            queryFn: () =>
                getPlaceholdersConfig({
                    appInstanceId
                }),
            cacheTime: 0,
            enabled: appInstanceId !== null && appInstanceId !== undefined
        });

    const {
        mutateAsync: setIsBlockedPlaceholders,
        isLoading: setPlaceholdersLoading
    } = useMutation({
        mutationFn: setPlaceholdersConfig
    });

    const loading = getPlaceholdersLoading || setPlaceholdersLoading;

    useEffect(() => {
        if (placeholdersConfigData) {
            setIsChecked(placeholdersConfigData.socialElementsBlocked);
        }
    }, [placeholdersConfigData]);

    const handleSwitchChange = async (event: ChangeEvent<HTMLInputElement>) => {
        sendEvent('Blocking - Enable placeholder toggled', {
            value: event.target.checked
        });
        setIsChecked(event.target.checked);

        try {
            await setIsBlockedPlaceholders({
                appInstanceId,
                blocked: event.target.checked
            });
            toast({
                title: t('appearancePage.placeholders.successMessage'),
                status: 'info',
                duration: 3000,
                isClosable: true
            });
        } catch (e) {
            setIsChecked(!event.target.checked);
            toast({
                title: t('appearancePage.placeholders.failedMessage'),
                status: 'error',
                duration: 3000,
                isClosable: true
            });

            return e;
        }

        return null;
    };

    return {
        isChecked,
        loading,
        handleSwitchChange,
        t
    };
};
