import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    Box,
    Checkbox,
    Spinner,
    Text,
    Tooltip
} from '@usercentrics/cookiebot-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { setIgnoreRecommendation } from '../../api/api';
import { useAmplitude } from '../../hooks';
import { useAuthStore } from '../../stores';

type Props = {
    templateId: string;
    recommendationIgnored: boolean;
};

export const IgnoreRecommendationsButton = ({
    templateId,
    recommendationIgnored
}: Props) => {
    const toast = useToast();
    const { appInstanceId, domain } = useAuthStore();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const [loading, setLoading] = useState(false);

    const { mutate: setIgnore } = useMutation({
        mutationFn: setIgnoreRecommendation,
        onSuccess: async () => {
            await queryClient.invalidateQueries([
                'scanReport',
                { appInstanceId, domainUrl: domain }
            ]);
            toast({
                title: t('reportPage.ignoreRecommendations.successMessage'),
                status: 'info',
                duration: 3000,
                isClosable: true
            });
        },
        onError: () => {
            setLoading(false);
            toast({
                title: t('reportPage.ignoreRecommendations.errorMessage'),
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        },
        onSettled: () => {
            setLoading(false);
        }
    });

    const handleIgnore = (event: any) => {
        sendEvent('Scan Report - Ignore recommendation clicked', {
            templateId,
            ignored: !recommendationIgnored
        });
        event.stopPropagation();

        setLoading(true);

        setIgnore({
            appInstanceId,
            payload: { templateId, ignored: !recommendationIgnored }
        });
    };

    return (
        <Tooltip
            label={t('reportPage.ignoreRecommendations.tooltipDescription')}
            placement="bottom"
            shouldWrapChildren
        >
            <Box
                display="flex"
                gap="8px"
                _hover={{
                    cursor: 'pointer'
                }}
            >
                {loading ? (
                    <Spinner size="md" speed="0.8s" />
                ) : (
                    <>
                        <Text>
                            {t('reportPage.ignoreRecommendations.ignore')}
                        </Text>
                        <Checkbox
                            defaultChecked={recommendationIgnored}
                            onChange={handleIgnore}
                            _hover={{
                                cursor: 'pointer'
                            }}
                        />
                    </>
                )}
            </Box>
        </Tooltip>
    );
};
