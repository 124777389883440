import { ScanStatusStartButton } from '@components/ScanStatus/ScanStatusStartButton';
import { Box, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { ScanStatusLastScan } from './ScanStatusLastScan';
import { ScanStatusNextSchedule } from './ScanStatusNextSchedule';
import { ScanStatusViewScanBtn } from './ScanStatusViewScanBtn';

type Props = {
    status: string;
    handleOnRescanClick: () => void;
};

export const ScanStatusDone = ({ status, handleOnRescanClick }: Props) => {
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" gap="16px">
            <ScanStatusLastScan status={status} />
            <Text>
                {t('dashboard.scanOverview.scanStatusDone.description')}
            </Text>
            <Box display="grid" gap="8px" gridTemplateColumns="1fr 1fr">
                <ScanStatusViewScanBtn />
                <ScanStatusStartButton
                    status={status}
                    hasIcon={false}
                    text={t('quotedScans.rescanButton')}
                    onClick={handleOnRescanClick}
                />
            </Box>
            <ScanStatusNextSchedule />
        </Box>
    );
};
