import { AppearanceConfiguration } from '../pages/Appearance/types/appearanceConfiguration.type';
import {
    LanguagesConfiguration,
    LanguageTranslation
} from '../pages/Content/types/Content.types';
import { ActiveScansResponse } from '../types/api';
import { StatusEnum } from '../util/constants';
import { callApiWithAuth } from './apiService';
import { LogRequest, NotificationsResponse } from './generated-types';

const BaseUrl = `${import.meta.env.VITE_API_BASE_URL}`;

export type ScanParams = {
    domainUrl: string | null;
    appInstanceId: string | null | undefined;
};

type GetInstanceParams = {
    appInstanceId: string;
};

const postHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
};

const textHeaders = {
    Accept: 'text/plain',
    'Content-Type': 'application/json'
};

const getScanResult = async (props: ScanParams) => {
    const { domainUrl, appInstanceId } = props;
    const params = new URLSearchParams({
        domainUrl: domainUrl as string,
        appInstanceId: appInstanceId as string
    });

    const response = await callApiWithAuth(
        `${BaseUrl}/wixAdapter/scan/getScanResult?${params}`
    );

    return response.json();
};

const getBannerComponents = async (props: ScanParams) => {
    const { domainUrl, appInstanceId } = props;
    const params = new URLSearchParams({
        domainUrl: domainUrl as string
    });

    try {
        const response = await callApiWithAuth(
            `${BaseUrl}/Banner/${appInstanceId}/components?${params}`
        );

        return response.json();
    } catch (error: any) {
        const errorResponse = await error.json();

        return Promise.resolve({
            status: StatusEnum.Failed,
            message: errorResponse.error?.description
        });
    }
};

const startScan = async (props: ScanParams) => {
    const body = JSON.stringify(props);
    const response = await callApiWithAuth(`${BaseUrl}/wixAdapter/scan/start`, {
        method: 'POST',
        body,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    });

    return response.json();
};

const getDomain = async (props: GetInstanceParams) => {
    const { appInstanceId } = props;

    const response = await callApiWithAuth(
        `${BaseUrl}/instances/v1/instance/${appInstanceId}`
    );

    return response.text();
};

export const setScriptSettingsId = async (props: {
    settingsId: string;
    dataCacheVersion: string;
    appInstanceId: string;
    disabled: boolean;
}) => {
    const body = JSON.stringify(props);
    const response = await callApiWithAuth(
        `${BaseUrl}/WixScripts/setScriptParam`,
        {
            method: 'POST',
            body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        }
    );

    return response.json();
};

export const getBannerScript = async ({
    appInstanceId
}: {
    appInstanceId: string;
}) => {
    const response = await callApiWithAuth(
        `${BaseUrl}/WixScripts/getScriptParam/?appInstanceId=${appInstanceId}`
    );

    return response.json();
};

export const setUpClientSettingsId = async ({
    appInstanceId
}: {
    appInstanceId: string;
}) => {
    const body = JSON.stringify({ instanceId: appInstanceId });

    const response = await callApiWithAuth(`${BaseUrl}/installations`, {
        method: 'POST',
        body,
        headers: textHeaders
    });

    return response.text();
};

const setAppearanceConfiguration = async ({
    appInstanceId,
    payload
}: {
    appInstanceId: string;
    payload: AppearanceConfiguration;
}) => {
    const body = JSON.stringify(payload);

    return callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/configuration`,
        {
            method: 'POST',
            body,
            headers: textHeaders
        }
    );
};

const getAppearanceConfiguration = async (
    appInstanceId: string
): Promise<AppearanceConfiguration> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/configuration`
    );

    return response.json();
};

export const getSupportedLanguages = async (): Promise<string[]> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/supportedLanguages`
    );

    return response.json();
};

export const getLanguagesConfiguration = async (
    appInstanceId: string
): Promise<LanguagesConfiguration> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/languages`
    );

    return response.json();
};

export const setLanguagesConfiguration = async ({
    appInstanceId,
    payload
}: {
    appInstanceId: string;
    payload: LanguagesConfiguration;
}) => {
    const body = JSON.stringify(payload);

    return callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/languages`,
        {
            method: 'POST',
            body,
            headers: textHeaders
        }
    );
};

export const getLanguageTranslations = async ({
    appInstanceId,
    sourceLanguage,
    targetLanguage
}: {
    appInstanceId: string;
    sourceLanguage: string;
    targetLanguage: string;
}): Promise<any> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/translation/?sourceLanguage=${sourceLanguage}&targetLanguage=${targetLanguage}`
    );

    return response.json();
};

export const getLanguageTranslationsLegalNotice = async ({
    appInstanceId,
    sourceLanguage
}: {
    appInstanceId: string;
    sourceLanguage: string;
}): Promise<any> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/translation/legal-notice/?sourceLanguage=${sourceLanguage}`
    );

    return response.json();
};

export const setLanguageTranslations = async ({
    appInstanceId,
    payload
}: {
    appInstanceId: string;
    payload: LanguageTranslation[];
}) => {
    const body = JSON.stringify(payload);

    return callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/translation`,
        {
            method: 'POST',
            body,
            headers: textHeaders
        }
    );
};

export type Trial = {
    active: boolean;
    available: boolean;
    endDate: string | null;
};

type PaidResponse = {
    paid: boolean;
    trial: Trial | null;
};

export const getIsPaid = async ({
    appInstanceId
}: {
    appInstanceId: string;
}): Promise<PaidResponse> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/is-paid`
    );

    return response.json();
};

export const getAppInstance = async ({
    appInstanceId
}: {
    appInstanceId: string;
}): Promise<any> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/WixAPi/instance/${appInstanceId}`
    );

    return response.json();
};

type PlaceholdersConfigResponse = {
    id: string;
    revision: string;
    createdDate: string;
    updatedDate: string;
    socialElementsBlocked: boolean;
};

export const getPlaceholdersConfig = async ({
    appInstanceId
}: {
    appInstanceId: string;
}): Promise<PlaceholdersConfigResponse> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/WixScripts/cmpConfig/${appInstanceId}`
    );

    return response.json();
};

type SetPlaceholdersConfigPayload = {
    appInstanceId: string;
    blocked: boolean;
};

export const setPlaceholdersConfig = async (
    payload: SetPlaceholdersConfigPayload
) => {
    const body = JSON.stringify(payload);
    const response = await callApiWithAuth(`${BaseUrl}/WixScripts/cmpConfig`, {
        method: 'PUT',
        body,
        headers: {
            Accept: 'text/plain',
            'Content-Type': 'application/json'
        }
    });

    return response.json();
};

export const getNotifications = async ({
    appInstanceId
}: {
    appInstanceId: string;
}): Promise<NotificationsResponse> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/notifications`
    );

    return response.json();
};

type SetReadNotificationsPayload = {
    appInstanceId: string;
    notificationIds: string[];
};

export const setReadNotifications = async (
    payload: SetReadNotificationsPayload
) => {
    const { appInstanceId, ...rest } = payload;
    const body = JSON.stringify(rest);
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/${appInstanceId}/notifications/read`,
        {
            method: 'POST',
            body,
            headers: textHeaders
        }
    );

    return response.text();
};

export const sendLog = async (payload: LogRequest) => {
    const body = JSON.stringify(payload);
    const response = await callApiWithAuth(`${BaseUrl}/logs`, {
        method: 'POST',
        body,
        headers: postHeaders
    });

    return response.text();
};

export const adminSignIn = async (password: string) => {
    const body = JSON.stringify(password);
    const response = await callApiWithAuth(
        `${BaseUrl}/admin-ui/password/check`,
        {
            method: 'POST',
            body,
            headers: textHeaders
        }
    );

    return response.text();
};

type GetStepsResponse = {
    id: string;
    isCompleted: boolean;
    name: string;
    wixAppInstanceId: string;
};

export const getSteps = async (): Promise<GetStepsResponse[]> => {
    const response = await callApiWithAuth(`${BaseUrl}/OnboardingSteps/steps`);

    return response.json();
};

export const setStepComplete = async ({
    stepId,
    value
}: {
    stepId: string;
    value: boolean;
}) => {
    const response = await callApiWithAuth(
        `${BaseUrl}/OnboardingSteps/steps/${stepId}/complete/${value}`,
        {
            method: 'POST',
            headers: postHeaders
        }
    );

    return response.json();
};

export const getShowOnboarding = async () => {
    const response = await callApiWithAuth(
        `${BaseUrl}/installations/showOnboardingBanner`
    );

    return response.json();
};

export const setShowOnboarding = async (value: boolean) => {
    const response = await callApiWithAuth(
        `${BaseUrl}/OnboardingSteps/show/${value}`,
        {
            method: 'POST',
            headers: postHeaders
        }
    );

    return response.json();
};

export const getScansDetails = async (
    appInstanceId: string
): Promise<ActiveScansResponse> => {
    const response = await callApiWithAuth(
        `${BaseUrl}/WixApi/instance/${appInstanceId}/activeScans`
    );

    return response.json();
};

export const resetCountScans = async (appInstanceId: string) => {
    const response = await callApiWithAuth(
        `${BaseUrl}/admin-ui/activescans/${appInstanceId}/clear`,
        {
            method: 'POST',
            headers: postHeaders
        }
    );

    return response.json();
};

type IgnoreRecommendationPayload = {
    templateId: string;
    ignored: boolean;
};

export const setIgnoreRecommendation = async ({
    appInstanceId,
    payload
}: {
    appInstanceId: string;
    payload: IgnoreRecommendationPayload;
}) => {
    const body = JSON.stringify(payload);
    const response = await callApiWithAuth(
        `${BaseUrl}/${appInstanceId}/recommendations/ignore`,
        {
            method: 'POST',
            headers: postHeaders,
            body
        }
    );

    return response.text();
};

export {
    getAppearanceConfiguration,
    getBannerComponents,
    getDomain,
    getScanResult,
    setAppearanceConfiguration,
    startScan
};
