import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { ScanParams, startScan } from '../api/api';
import { reportStoreActions, useAuthStore } from '../stores';
import { StatusEnum } from '../util/constants';

export const useStartScan = () => {
    const { domain, appInstanceId } = useAuthStore();

    const client = useQueryClient();

    const startScanParams: ScanParams = useMemo(
        () => ({
            appInstanceId,
            domainUrl: domain
        }),
        [domain, appInstanceId]
    );

    const { mutateAsync: startScanAsync, isLoading: isStartScanLoading } =
        useMutation({
            mutationFn: startScan,
            onSuccess: () => {
                client
                    .invalidateQueries([
                        'scanReport',
                        {
                            appInstanceId,
                            domainUrl: domain
                        }
                    ])
                    .then();
            }
        });

    const handleStartScan = useCallback(async () => {
        if (appInstanceId === 'Test') {
            return;
        }

        await startScanAsync(startScanParams, {
            onSuccess: () => {
                reportStoreActions.setStatus(StatusEnum.Pending);
            }
        });
    }, [appInstanceId, startScanAsync, startScanParams]);

    return {
        handleStartScan,
        isStartScanLoading
    };
};
