import { ScanStatusStartButton } from '@components/ScanStatus/ScanStatusStartButton';
import {
    Box,
    MdErrorOutline,
    Tag,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAuthStore, useReportStore } from '../../stores';
import { getTranslation } from '../../util/getTranslation';

type Props = {
    status: string;
    handleOnRescanClick: () => void;
};

export const ScanStatusError = ({ status, handleOnRescanClick }: Props) => {
    const { error } = useReportStore();
    const { appInstanceId } = useAuthStore();
    const { t } = useTranslation();

    return (
        <Box display="flex" flexDirection="column" gap="16px">
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                w="100%"
            >
                <Box display="flex" gap="16px">
                    <Text fontWeight="600">
                        {t('dashboard.scanOverview.scanStatusError.title')}
                    </Text>
                </Box>
                <Box>
                    <Tag background="#9F1818">
                        <Text display="flex" alignItems="center" gap="8px">
                            <MdErrorOutline fontSize={16} /> {t('common.error')}
                        </Text>
                    </Tag>
                </Box>
            </Box>
            <Text>
                {getTranslation(
                    `dashboard.scanOverview.scanStatusError.${error.code}`,
                    'dashboard.scanOverview.scanStatusError.generic',
                    {
                        appInstanceId,
                        utcTimestamp: new Date().toISOString()
                    }
                )}
            </Text>
            <ScanStatusStartButton
                status={status}
                hasIcon={false}
                text={t('quotedScans.rescanButton')}
                onClick={handleOnRescanClick}
            />
        </Box>
    );
};
