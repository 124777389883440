import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { getSteps, setStepComplete } from '../api/api';
import {
    appConfigStoreActions,
    useAppConfigStore,
    useAuthStore,
    useReportStore
} from '../stores';
import { StatusEnum } from '../util/constants';
import { useAmplitude } from './useAmplitude';
import { useDisplayOnboarding } from './useDisplayOnboarding';

enum ActionType {
    Redirect = 'redirect',
    Action = 'action'
}

type Action = {
    type: ActionType;
    handler: string | (() => void);
    disabled?: boolean;
};

export const useOnboarding = () => {
    const client = useQueryClient();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { mutateShowOnboarding, showOnboardingIsLoading } =
        useDisplayOnboarding();
    const { isOnboardingEnabled } = useAppConfigStore();
    const { status } = useReportStore();
    const { domain } = useAuthStore();
    const { sendEvent } = useAmplitude();

    const {
        data,
        isLoading: isStepsLoading,
        isRefetching
    } = useQuery({
        queryKey: ['getSteps'],
        queryFn: getSteps,
        cacheTime: 0
    });

    const responseData = data ?? [];

    const { mutate: setComplete, isLoading: isSetStepCompleteLoading } =
        useMutation({
            mutationFn: setStepComplete,
            cacheTime: 0,
            onSettled: () => {
                client.invalidateQueries(['getSteps']);
            }
        });

    const actionsMap: Record<number, Action> = useMemo(
        () => ({
            1: {
                type: ActionType.Redirect,
                handler: '/report',
                disabled: status !== StatusEnum.Done || !domain
            },
            2: {
                type: ActionType.Redirect,
                handler: '/banner/appearance'
            },
            3: {
                type: ActionType.Action,
                handler: () =>
                    appConfigStoreActions.setTriggerScriptChange(true),
                disabled: !domain
            }
        }),
        [domain, status]
    );

    const handleOnChange = (checked: boolean, stepId: string) => {
        const stepIndex = responseData.findIndex((step) => step.id === stepId);
        sendEvent(
            `Onboarding - Step ${stepIndex} marked as ${
                checked ? 'done' : 'not done'
            }`,
            {
                stepId
            }
        );
        setComplete({ stepId, value: checked });
    };

    const stepsCompleted = responseData.filter(
        (step) => step.isCompleted
    ).length;

    const handleAction = (index: number) => {
        const action = actionsMap[index];
        sendEvent(`Onboarding - step ${index} action clicked`);

        if (action.disabled) {
            return;
        }

        if (
            action.type === ActionType.Redirect &&
            typeof action.handler === 'string'
        ) {
            navigate(action.handler);
        } else if (
            action.type === ActionType.Action &&
            typeof action.handler === 'function'
        ) {
            action.handler();
        }
    };

    const allStepsCompleted = responseData?.every((step) => step.isCompleted);

    const firstNotCompleted = responseData?.findIndex(
        (step) => !step.isCompleted
    );

    const isLoading =
        isSetStepCompleteLoading ||
        isStepsLoading ||
        isRefetching ||
        showOnboardingIsLoading;

    useEffect(() => {
        if (allStepsCompleted) {
            sendEvent('Onboarding - All steps completed');
        }
    }, [allStepsCompleted, sendEvent]);

    const onHideOnboarding = () => {
        sendEvent(
            allStepsCompleted
                ? 'Onboarding - Complete onboarding clicked'
                : 'Onboarding - Skip onboarding clicked'
        );
        mutateShowOnboarding(false);
    };

    return {
        mutateShowOnboarding,
        data: responseData,
        handleOnChange,
        stepsCompleted,
        handleAction,
        allStepsCompleted,
        firstNotCompleted,
        isLoading,
        t,
        isOnboardingEnabled,
        actionsMap,
        onHideOnboarding
    };
};
