import { ThemeCustom } from '@assets/ThemeCustom';
import { ThemeDark } from '@assets/ThemeDark';
import { ThemeDefault } from '@assets/ThemeDefault';
import {
    Label,
    RadioCard,
    RadioCardBody,
    RadioCardGroup,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../../hooks';
import { ConsentBannerFormState } from '../../../pages/Appearance/types/consentBanner.type';

const themes = [
    {
        label: 'appearancePage.consentBanner.theme.default',
        icon: ThemeDefault,
        isDefault: true,
        value: 'default'
    },
    {
        label: 'appearancePage.consentBanner.theme.dark',
        icon: ThemeDark,
        value: 'dark'
    },
    {
        label: 'appearancePage.consentBanner.theme.custom',
        icon: ThemeCustom,
        value: 'custom'
    }
];

type Props = {
    theme: ConsentBannerFormState['theme'];
    setConsentBannerFormState: Dispatch<SetStateAction<ConsentBannerFormState>>;
};

export const ContentBannerTheme = ({
    theme,
    setConsentBannerFormState
}: Props) => {
    const { t } = useTranslation();
    const { sendEvent } = useAmplitude();

    const handleOnChange = (val: string) => {
        sendEvent('Appearance - Banner update theme', {
            theme: val
        });
        setConsentBannerFormState((prevState: ConsentBannerFormState) => ({
            ...prevState,
            theme: val as ConsentBannerFormState['theme']
        }));
    };

    return (
        <Stack spacing="14px">
            <Stack spacing="4px">
                <Text fontSize="21" fontWeight="600">
                    {t('appearancePage.consentBanner.themeTitle')}
                </Text>
                <Text>{t('appearancePage.consentBanner.themeDesc')}</Text>
            </Stack>
            <RadioCardGroup value={theme} onChange={handleOnChange} spacing={4}>
                <Stack direction="row" spacing={2} flexWrap="wrap">
                    {themes.map((optionTheme) => {
                        const { icon: IconCmp, label, value } = optionTheme;

                        return (
                            <Label textAlign="center" key={value} width="220px">
                                <RadioCard value={value}>
                                    <RadioCardBody
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <IconCmp />
                                    </RadioCardBody>
                                </RadioCard>
                                {t(label)}
                            </Label>
                        );
                    })}
                </Stack>
            </RadioCardGroup>
        </Stack>
    );
};
