import {
    Box,
    MdDeleteOutline,
    Stack,
    Switch,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../../hooks';
import { LanguageSettingsFormState } from '../../../pages/Content/types/Content.types';
import { FormUpdate } from '../../../types';
import { SectionTitle } from '../../Form/SectionTitle';

type Props = {
    addedLanguages: LanguageSettingsFormState['addedLanguages'];
    editableLanguages: string[];
} & FormUpdate;

export const AddedLanguages = ({
    addedLanguages,
    updateForm,
    editableLanguages
}: Props) => {
    const { t } = useTranslation('translation', {
        keyPrefix: 'contentPage.languageSettings.addedLanguages'
    });
    const { sendEvent } = useAmplitude();

    const handleOnActiveChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        languageValue: string
    ) => {
        sendEvent('Content - language active change', {
            value: languageValue,
            active: event.target.checked
        });
        const updatedLanguages = addedLanguages.map((language) => {
            if (language.value === languageValue) {
                return {
                    ...language,
                    active: event.target.checked
                };
            }

            return language;
        });
        updateForm({
            id: 'addedLanguages',
            value: updatedLanguages
        });
    };

    const handleOnDelete = (languageValue: string) => {
        sendEvent('Content - language delete', {
            value: languageValue
        });
        const isCurrentlyActive = addedLanguages.find(
            (language) =>
                language.value === languageValue &&
                (language.active || editableLanguages.includes(language.value))
        );

        if (isCurrentlyActive) {
            return;
        }
        const updatedLanguages = addedLanguages.filter(
            (language) => language.value !== languageValue
        );
        updateForm({
            id: 'addedLanguages',
            value: updatedLanguages
        });
    };

    return (
        <Box>
            <Stack spacing="24px">
                <SectionTitle>{t('title')}</SectionTitle>
                <Stack spacing="4px">
                    {addedLanguages.map((language, index) => (
                        <Box
                            pl="24px"
                            pr="8px"
                            borderRadius="4px"
                            key={language.value}
                            backgroundColor="#F2F2F2"
                        >
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                height="56px"
                            >
                                <Text fontSize="15px" fontWeight={600}>
                                    {language.label}
                                </Text>
                                <Box display="flex" alignItems="center">
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="8px"
                                        borderRight="1px solid #D6D6D6"
                                        pr="8px"
                                    >
                                        <Text
                                            color="#666"
                                            fontSize="13px"
                                            fontWeight={500}
                                        >
                                            {language.active
                                                ? t('active')
                                                : t('disabled')}
                                        </Text>
                                        <Switch
                                            size="sm"
                                            isChecked={language.active}
                                            disabled={index === 0}
                                            onChange={(event) =>
                                                handleOnActiveChange(
                                                    event,
                                                    language.value
                                                )
                                            }
                                        />
                                    </Box>
                                    <Box
                                        px="16px"
                                        justifySelf="center"
                                        _hover={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() =>
                                            handleOnDelete(language.value)
                                        }
                                    >
                                        <MdDeleteOutline
                                            size={24}
                                            fill={
                                                language.active ||
                                                editableLanguages.includes(
                                                    language.value
                                                )
                                                    ? '#666666'
                                                    : '#141414'
                                            }
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Stack>
            </Stack>
        </Box>
    );
};
