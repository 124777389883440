import { useToast } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAmplitude, useFormState } from '../../hooks';
import { useAuthStore } from '../../stores';
import { UpdateFormInput } from '../../types';
import { deepCopy, deepEqual } from '../../util/generalUtils';
import {
    mapResponseToTextLabels,
    mapTextLabelsToRequest,
    textLabelsInitialFormState
} from './contentUtils';
import {
    Language,
    LanguageSettingsFormState,
    LanguageTranslation,
    TextLabelsFormState
} from './types/Content.types';
import { useContentQueries } from './useContentQueries';

export const useContent = () => {
    const { t } = useTranslation();
    const [textLabelsFormState, setTextLabelsFormState] =
        useState<TextLabelsFormState>(textLabelsInitialFormState);
    const [languageSettingsFormState, setLanguageSettingsFormState] =
        useState<LanguageSettingsFormState>({
            addedLanguages: []
        });
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
        useState<boolean>(true);

    const { appInstanceId } = useAuthStore();
    const toast = useToast();
    const { sendEvent } = useAmplitude();

    const {
        supportedLanguages,
        isGetSupportedLanguagesLoading,
        setLanguageTranslation,
        isSetLanguageTranslationLoading,
        languageTranslationsData,
        languageTranslationsLoading,
        setLanguagesConfig,
        languagesConfigData,
        isSetLanguagesConfigLoading,
        languagesConfigDataLoading,
        languageTranslationsDataLegalNotice,
        languageTranslationsLegalNoticeLoading
    } = useContentQueries({
        appInstanceId,
        selectedLanguage: textLabelsFormState.selectedLanguage
    });

    const textLabelsFormRef = useRef<TextLabelsFormState>();
    const languageSettingsFormRef = useRef<LanguageSettingsFormState>();

    const isSaveButtonLoading =
        isSetLanguagesConfigLoading || isSetLanguageTranslationLoading;

    const loading =
        languagesConfigDataLoading ||
        isGetSupportedLanguagesLoading ||
        languageTranslationsLoading ||
        languageTranslationsLegalNoticeLoading;

    const languagesListForSelection: Language[] = useMemo(() => {
        if (languagesConfigData) {
            return languagesConfigData.editableLanguages.map(
                (language: string) => ({
                    label:
                        supportedLanguages.find(
                            (lang) => lang.value === language
                        )?.label ?? language,
                    value: language,
                    active: languagesConfigData.activeLanguages.includes(
                        language
                    )
                })
            );
        }

        return [];
    }, [languagesConfigData, supportedLanguages]);

    useEffect(() => {
        if (
            languagesConfigData &&
            languageTranslationsData &&
            languageTranslationsDataLegalNotice
        ) {
            const mappedLanguageSettingsFormState = {
                addedLanguages: languagesListForSelection || []
            };

            const mappedTextLabelsFormState = mapResponseToTextLabels(
                languageTranslationsData,
                languageTranslationsDataLegalNotice,
                textLabelsFormState.selectedLanguage
            );
            setLanguageSettingsFormState(mappedLanguageSettingsFormState);
            setTextLabelsFormState(mappedTextLabelsFormState);
            languageSettingsFormRef.current = deepCopy(
                mappedLanguageSettingsFormState
            );
            textLabelsFormRef.current = deepCopy(mappedTextLabelsFormState);
        }
    }, [
        languageTranslationsData,
        languageTranslationsDataLegalNotice,
        languagesConfigData,
        languagesListForSelection,
        supportedLanguages,
        textLabelsFormState.selectedLanguage
    ]);

    useEffect(() => {
        if (textLabelsFormRef.current && languageSettingsFormRef.current) {
            if (
                !deepEqual(textLabelsFormState, textLabelsFormRef.current) ||
                !deepEqual(
                    languageSettingsFormState,
                    languageSettingsFormRef.current
                )
            ) {
                setIsSaveButtonDisabled(false);
            } else {
                setIsSaveButtonDisabled(true);
            }
        }
    }, [textLabelsFormState, languageSettingsFormState]);

    const editableLanguages = useMemo(
        () => languagesConfigData?.editableLanguages || ['en'],
        [languagesConfigData]
    );

    const activeLanguages = useMemo(
        () => languagesConfigData?.activeLanguages || ['en'],
        [languagesConfigData]
    );

    const { updateForm } = useFormState();

    const updateTextLabelsForm = (updateFormInput: UpdateFormInput) => {
        sendEvent('Content - Update text labels', {
            ...updateFormInput
        });
        updateForm(setTextLabelsFormState, updateFormInput);
    };

    const updateLanguageSettingsForm = (updateFormInput: UpdateFormInput) => {
        sendEvent('Content - Update language settings', {
            ...updateFormInput
        });
        updateForm(setLanguageSettingsFormState, updateFormInput);
    };

    const handleOnReset = () => {
        sendEvent('Content - Reset button clicked');

        if (
            !isSaveButtonDisabled &&
            languageSettingsFormRef.current &&
            textLabelsFormRef.current
        ) {
            setLanguageSettingsFormState(
                deepCopy(languageSettingsFormRef.current)
            );
            setTextLabelsFormState(deepCopy(textLabelsFormRef.current));
        }
    };

    const handleOnSubmit = async () => {
        sendEvent('Content - Save banner configuration');
        const payload = {
            appInstanceId,
            payload: {
                editableLanguages: languageSettingsFormState.addedLanguages.map(
                    (language) => language.value
                ),
                activeLanguages: languageSettingsFormState.addedLanguages
                    .filter((language) => language.active)
                    .map((language) => language.value)
            }
        };
        const translationPayload: LanguageTranslation[] =
            mapTextLabelsToRequest(textLabelsFormState);

        try {
            await setLanguagesConfig(payload);
            await setLanguageTranslation({
                appInstanceId,
                payload: translationPayload
            });
            toast({
                title: t('contentPage.successMessage'),
                status: 'info',
                duration: 3000,
                isClosable: true
            });
        } catch {
            toast({
                title: t('contentPage.errorMessage'),
                status: 'error',
                duration: 3000,
                isClosable: true
            });
        }
    };

    return {
        textLabelsFormState,
        updateTextLabelsForm,
        updateLanguageSettingsForm,
        languageSettingsFormState,
        editableLanguages,
        activeLanguages,
        isSaveButtonLoading,
        loading,
        handleOnSubmit,
        isSaveButtonDisabled,
        handleOnReset,
        supportedLanguages,
        languagesListForSelection
    };
};
