export type KeyofType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: P;
};

export type ComponentGroup = {
    name: string;
    type: string;
    items: Component[];
};

export enum ComponentState {
    BLOCKED = 'BLOCKED',
    ACTION_REQUIRED = 'ACTION_REQUIRED',
    NONE = ''
}

export type RecommendedAction = {
    value: string;
    error: string;
};

export type Component = {
    name: string;
    description: string;
    recommendedAction: RecommendedAction;
    blockerCategory: string;
    recommendedCategory: string;
    blockedPriorConsent: boolean;
    hasConflict: boolean;
    type: string;
    badgeStatus: string;
    state: ComponentState;
    templateId: string;
    recommendationIgnored: boolean;
    mappedUrls?: string[];
    title?: string;
    showInLiveBanner?: boolean;
};

export type UpdateFormInput = {
    formSection?: string;
    subSection?: string;
    id: string;
    value: any;
};

export type FormUpdate = {
    updateForm: (payload: UpdateFormInput) => void;
};

export type SvgProps = {
    fill?: string | null;
    iconColor?: string | null;
    width?: string | null;
    height?: string | null;
};

export type Error = {
    code: string;
    description: string;
    status?: any;
};
