import { Button } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';

import { useStartScan } from '../../hooks/useStartScan';
import { StatusEnum } from '../../util/constants';

type Props = {
    status: string;
    hasIcon?: boolean;
    text?: string;
    onClick?: () => void;
};

export const ScanStatusStartButton = ({
    status,
    hasIcon = true,
    text,
    onClick
}: Props) => {
    const { t } = useTranslation();
    const { handleStartScan } = useStartScan();

    const handleOnScanStart = async () => {
        if (status === StatusEnum.Pending) {
            return;
        }

        if (onClick) {
            onClick();

            return;
        }

        await handleStartScan();
    };

    return (
        <Button onClick={handleOnScanStart}>
            {text ?? t('dashboard.scanOverview.scanStatusStart.startScan')}
            {hasIcon && <MdOutlineKeyboardArrowRight />}
        </Button>
    );
};
