import { Box, Button, Stack, Text } from '@usercentrics/cookiebot-ui-react';
import { useTranslation } from 'react-i18next';

import { useAmplitude } from '../../hooks';
import { Links } from '../../util/constants';

type Props = {
    onClose: () => void;
};

const eventCategory = 'Feedback Thumb Down View';

export const ThumbsDownContent = ({ onClose }: Props) => {
    const { sendEvent } = useAmplitude();
    const { t } = useTranslation();

    const handleOnClose = () => {
        onClose();
        sendEvent(`${eventCategory} - no thanks clicked`);
    };

    const handleOnGoToSupport = () => {
        onClose();
        sendEvent(`${eventCategory} - go to support clicked`);
    };

    return (
        <Stack spacing="32px">
            <Text fontSize="32px">
                {t('feedbackModal.thumbDownView.description')}
            </Text>
            <Box display="flex" gap="8px" justifyContent="center">
                <Button variant="outline" onClick={handleOnClose}>
                    {t('feedbackModal.thumbDownView.noText')}
                </Button>
                <Button
                    as="a"
                    onClick={handleOnGoToSupport}
                    href={Links.OpenTicket}
                    target="_blank"
                >
                    {t('feedbackModal.thumbDownView.okText')}
                </Button>
            </Box>
        </Stack>
    );
};
