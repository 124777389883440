import { useToast } from '@chakra-ui/react';
import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Text
} from '@usercentrics/cookiebot-ui-react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IoWarningOutline } from 'react-icons/io5';

import { useAmplitude } from '../../hooks';
import { useStartScan } from '../../hooks/useStartScan';
import { ActiveScansResponse } from '../../types/api';
import { Colors } from '../../util/constants';

type Props = {
    isOpen: boolean;
    onClose: () => void;
} & ActiveScansResponse;

const convertToDateTime = (time: string) => {
    const [hours, minutes, seconds] = time.split(':');
    const [secs, millis] = seconds.split('.').map(Number);
    const now = new Date();

    now.setHours(now.getHours() + Number(hours));
    now.setMinutes(now.getMinutes() + Number(minutes));
    now.setSeconds(now.getSeconds() + secs);
    now.setMilliseconds(now.getMilliseconds() + millis);

    return now;
};

const SCANS_LIMIT = 5;

export const ManualScanConfirmationDialog = ({
    isOpen,
    onClose,
    canExecuteMoreManualScans,
    nextAvailableScan,
    activeScansCount
}: Props) => {
    const { t } = useTranslation();
    const { handleStartScan, isStartScanLoading } = useStartScan();
    const { sendEvent } = useAmplitude();
    const toast = useToast();

    const onStartScan = async () => {
        sendEvent('Quoted scans - Yes, Im ready to scan clicked');

        try {
            await handleStartScan();
            onClose();
        } catch (error) {
            toast({
                title: t('quotedScans.dialog.startScanErrorMessage'),
                status: 'error',
                duration: 10000,
                isClosable: true
            });
        }
    };

    const handleOnClose = () => {
        sendEvent('Quoted scans - No, Im not ready clicked');
        onClose();
    };

    const limitResetDate = useMemo(() => {
        if (nextAvailableScan && activeScansCount && activeScansCount > 0) {
            const resetDate = convertToDateTime(nextAvailableScan);

            return `${resetDate.toLocaleDateString(
                'en-GB'
            )} ${resetDate.toLocaleTimeString('en-GB', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            })}`;
        }

        return '';
    }, [activeScansCount, nextAvailableScan]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            id="manual-scan-confirmation-dialog"
        >
            <ModalOverlay />
            <ModalContent px="32px" pt="24px" pb="40px">
                <ModalHeader>
                    <Box display="flex" alignItems="center" gap="8px">
                        <IoWarningOutline
                            color={Colors.DefaultBlue}
                            size="24px"
                        />
                        {t('quotedScans.dialog.title')}
                    </Box>
                </ModalHeader>
                <ModalBody>
                    <Box bg="#FDECCF" p="16px" borderRadius="8px">
                        <Stack spacing="16px">
                            <Text fontSize="16px" fontWeight={600}>
                                {t('quotedScans.dialog.descTitle1', {
                                    scansLimit: SCANS_LIMIT
                                })}
                            </Text>

                            {canExecuteMoreManualScans ? (
                                <>
                                    <Text>{t('quotedScans.dialog.desc1')}</Text>

                                    <Text>{t('quotedScans.dialog.desc2')}</Text>
                                </>
                            ) : (
                                <Text>
                                    {t('quotedScans.dialog.scansLimitReached')}
                                </Text>
                            )}

                            <Box>
                                {!canExecuteMoreManualScans ? (
                                    <Text fontStyle="italic">
                                        {t(
                                            'quotedScans.dialog.limitResetNotice'
                                        )}{' '}
                                        {limitResetDate}
                                    </Text>
                                ) : null}
                                <Text fontStyle="italic">
                                    {t('quotedScans.dialog.activeScansCount', {
                                        activeScans: `${activeScansCount}/${SCANS_LIMIT}`
                                    })}
                                </Text>
                            </Box>
                        </Stack>
                    </Box>
                </ModalBody>
                <ModalFooter>
                    <Button variant="outline" onClick={handleOnClose}>
                        {t('quotedScans.dialog.cancel')}
                    </Button>
                    <Button
                        onClick={onStartScan}
                        isDisabled={
                            !canExecuteMoreManualScans || isStartScanLoading
                        }
                        isLoading={isStartScanLoading}
                    >
                        {t('quotedScans.dialog.confirm')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
